.button {
    position: absolute;
    bottom: 12%;
}

.title {
    padding: 2px 10px;
    width: 125px;
    text-align: center;
    background-color: #5a62cf;
    color: white;
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);
}

.icon {
    width: 21px;
    height: 21px;
    position: absolute;
    bottom: 25px;
    left: 15px;
}