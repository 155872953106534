.layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
    .layout {
        padding-bottom: 120px;
    }
}