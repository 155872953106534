.basket {
  /* width: 49%; */
  position: relative;
  cursor: pointer;
  margin-top: 40px;
}

.image {
  width: 75px;
  margin-bottom: 7px;
}

.title {
  padding: 2px 10px;
  width: 125px;
  text-align: center;
  background-color: #5a62cf;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 20%);
}

.counter {
  width: 20px;
  height: 20px;
  background-color: #5a62cf;
  margin-top: 5px;
  display: block;
  line-height: normal;
  font-size: 0.9rem;
  position: relative;
  top: 8px;
}

.icon {
  width: 21px;
  height: 21px;
  position: absolute;
  bottom: 5px;
  left: -10px;
}