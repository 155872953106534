.basket {
  /* width: 49%; */
  margin-top: 25px;
}

.basket:first-of-type {
  margin-top: 0;
}

.basket:nth-of-type(2) {
  margin-top: 0;
}

.image {
  width: 75px;
}

.logo {
  width: 40px;
  display: block;
  margin: 0 auto;
  position: absolute;
  top: -5px;
  z-index: -1;
}
.title {
  padding: 2px 10px;
  width: 125px;
  text-align: center;
  background-color: #5a62cf;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 20%);
}

.counter {
  width: 20px;
  height: 20px;
  background-color: #5a62cf;
  margin-top: 5px;
  display: block;
  line-height: normal;
  font-size: 0.9rem;
  position: relative;
  top: 8px;
}