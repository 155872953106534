.wrapper {
    border: 1px solid #eaebec;
    padding: 20px;
    background-color: #fff;
    width: 100%;

    display: flex;
    flex-direction: column;
}

.selectedDateInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.weekDays {
    margin-top: 20px;
}